<template>
  <div>
    <v-layout wrap justify-center pt-md-16>
      <v-flex xs12 align-self-center text-center pt-4 pt-sm-12 pt-lg-16>
        <v-card flat text-center class="pt-0">
          <v-layout wrap justify-center pt-xl-16>
            <v-flex xs12 py-5 align-self-center>
              <h2>Your payment is being processed.</h2>
            </v-flex>
            <v-flex
              xs12
              px-3
              align-self-center
              :text-justify="$vuetify.breakpoint.name == 'xs' ? true : false"
            >
              <span
                style="
                  font-size: 14px !important;
                  text-align: center !important;
                  color: black;
                "
              >
                <b>
                  You will be taken to a confirmation page when your payment is
                  complete, or you can leave this page while your payment is
                  being processed and return later.
                  <br />
                  You will be sent a confirmation email once payment is
                  received.
                  <br />
                  <br />
                
                </b>
              </span>
            </v-flex>

            <v-flex xs12 py-3 align-self-center text-center>
              <a href="/Shopping">Click here to redirect to home page.</a>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    window.setInterval(() => {
      this.paymentStatus();
    }, 20000);
  },
  methods: {
    paymentStatus() {
      axios({
        method: "GET",
        url: "/booking/paymentstatus",
        params: {
          transid: this.$route.query.pay,
        },
      })
        .then((response) => {
          if (response.data.status) {
            var PaymentData = null;
            PaymentData = response.data.data;
            if (PaymentData.payment_status === "success") {
              location.href = "https://www.jobs.wti.org.in/Shopping/orders";
            } else if (PaymentData.payment_status === "failure") {
              location.href = "https://www.jobs.wti.org.in/product/" + this.$route.query.prod;
            }
          } else if (
            response.data.msg == "Failed to verify the payment status"
          ) {
            location.href =
              "https://www.jobs.wti.org.in/Wait?pay=" +
              this.$route.query.pay;
          }
        })
        .catch(() => {
          console.log("error");
        });
    },
  },
};
</script>